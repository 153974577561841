<template>
  <div class="jobs p-8">
    <div class="mx-auto max-w-5xl space-y-6">
      <div class="flex justify-start items-center gap-1">
        <svg
          @click="router.go(-1)"
          class="h-8 w-8 text-blue-600 hover:text-blue-700 cursor-pointer"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M11 15l-3-3m0 0l3-3m-3 3h8M3 12a9 9 0 1118 0 9 9 0 01-18 0z"
          />
        </svg>
        <h1 class="text-3xl font-semibold ">
          Edit Job {{ job.sequence_number }}
        </h1>
      </div>

      <div v-if="isLoading" class="flex justify-center gap-1">
        <div class="spinner dark"></div>
        <div>Loading</div>
      </div>

      <form @submit.prevent="updateJob" class="max-w-3xl space-y-5">
        <div class="text-lg space-y-5">
          <div class="flex items-center">
            <button
              type="button"
              @click="job.job_completed = !job.job_completed"
              :disabled="job.job_locked"
              :class="{ 'bg-blue-500': job.job_completed }"
              class="bg-gray-200 relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
            >
              <span
                :class="{ 'translate-x-5': job.job_completed }"
                class="translate-x-0 pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
              ></span>
            </button>
            <span class="ml-3">
              <span class="text-sm font-medium text-gray-900"
                >Job Completed</span
              >
            </span>
          </div>

          <div>
            <label
              for="job_scheduled"
              class="block text-sm font-medium text-gray-600"
            >
              Scheduled Date
            </label>
            <input
              :value="job.job_scheduled"
              @change="jobScheduled"
              :disabled="job.job_locked"
              name="job_scheduled"
              type="date"
              class="mt-1 w-full rounded px-4 py-2"
            />
          </div>

          <div>
            <label
              for="selected_crew"
              class="block text-sm font-medium text-gray-600"
            >
              Select Crew
            </label>
            <div class="flex gap-2">
              <select
                v-model="job.crew"
                :disabled="job.job_locked"
                name="selected_crew"
                class="w-full border rounded px-4 py-2 border-gray-200 text-lg"
              >
                <option value=""> </option>
                <option v-for="crew in crews" :key="crew.id" :value="crew">{{
                  crew.crew_name
                }}</option>
              </select>
            </div>
          </div>

          <div class="pt-2 flex justify-center" v-if="!job.job_locked">
            <button
              type="submit"
              class="bg-blue-600 submit-btn text-white text-2xl font-bold w-60 rounded py-2 flex justify-center gap-2"
            >
              <div v-if="isPending" class="spinner"></div>
              Save
            </button>
          </div>

          <div class="text-center text-red-600 font-semibold">
            {{ error }}
            {{ crews_err }}
            {{ jobs_err }}
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import getCollection from "@/composables/getCollection";
import getDocument from "@/composables/getDocument";
import useDocument from "@/composables/useDocument";

export default {
  props: ["job_id", "user"],
  setup(props) {
    const isLoading = ref(true);
    const route = useRoute();
    const router = useRouter();
    const isPending = ref(false);

    const business_id = props.user.claims.business_id ?? props.id;

    // read functions
    const { error: crews_err, documents: crews } = getCollection(
      `businesses/${business_id}/crews`
    );

    const { error, document: job } = getDocument(
      `businesses/${business_id}/jobs`,
      props.job_id
    );

    // write function
    const { error: jobs_err, updateDoc: jobs_updateDoc } = useDocument(
      `businesses/${business_id}/jobs`,
      props.job_id
    );

    watch(job, () => {
      isLoading.value = false;
    });

    const jobScheduled = (event) => {
      job.value.job_scheduled = event.target.value;
    };

    const updateJob = async () => {
      isPending.value = true;
      if (job.job_locked) return;
      await jobs_updateDoc({
        job_scheduled: job.value.job_scheduled ?? "",
        crew: job.value.crew ?? "",
        job_completed: job.value.job_completed,
        job_locked: job.value.job_completed,
      });
      isPending.value = false;
      router.push(`/jobs/${props.job_id}`);
    };

    return {
      isLoading,
      route,
      router,
      isPending,
      crews,
      crews_err,
      error,
      job,
      jobs_err,
      jobScheduled,
      updateJob,
    };
  },
};
</script>
